import {
  Box,
  Flex,
  VStack,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

const PaymentPlans = ({
  isHomePage = true,
  onSelectPlan,
  isPaymentStarted,
}) => {
  // Theme-based colors
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const highlightColor = useColorModeValue("purple.500", "purple.300");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const cardBgGradientLight = "linear(to-b, purple.50, white)";
  const cardBgGradientDark = "linear(to-b, gray.700, gray.800)";
  const cardBgGradient = useColorModeValue(
    cardBgGradientLight,
    cardBgGradientDark
  );
  const textColor = useColorModeValue("gray.600", "gray.400");
  const priceColor = useColorModeValue("gray.800", "white");

  const plans = [
    {
      title: "Free Plan",
      features: [
        "Start with 100k free AI tokens/credits upon sign-up",
        "Access to basic mock test customization",
        "PDF-based mock tests",
        "Voice-based mock tests",
        "PDF solutions evaluation",
        "Basic analytics to track your progress",
        "OMR sheet evaluation",
        "No token expiry – use at your own pace",
      ],
      price: "Free",
    },
    {
      title: "Basic Plan",
      features: [
        "500k AI tokens/credits to power your learning",
        "Full access to all mock test customization features",
        "PDF-based mock tests with enhanced options",
        "Voice-based mock tests",
        "Comprehensive PDF solutions evaluation",
        "Advanced analytics for in-depth insights",
        "OMR sheet evaluation",
        "No token expiry – lifetime access",
      ],
      price: 500,
    },
    {
      title: "Pro Plan",
      features: [
        "1 million AI tokens/credits – the ultimate package",
        "Complete access to all customization and testing features",
        "Enhanced PDF-based mock tests for deeper analysis",
        "Voice-based mock tests",
        "Full PDF solutions evaluation with expert-level insights",
        "Advanced analytics for 360-degree performance insights",
        "OMR sheet evaluation",
        "No token expiry – lifetime access",
      ],
      price: 1000,
    },
  ];

  return (
    <Box bg={bgColor} p={{ base: 2, lg: 8 }} mb="10%">
      <Flex wrap="wrap" justify="center" gap={6} mx="auto">
        {plans
          .filter((_, index) => isHomePage || index > 0) // Show only index 1 and 2 if not home page
          .map((plan, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="xl"
              p={{ base: 4, lg: 8 }}
              bgGradient={cardBgGradient}
              boxShadow="lg"
              transition="all 0.3s ease"
              _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
              width={{
                base: "90%",
                sm: "80%",
                lg: `${isHomePage ? "25%" : "40%"}`,
              }}
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text
                fontSize="2xl"
                fontWeight="extrabold"
                color={highlightColor}
                mb={4}
              >
                {plan.title}
              </Text>

              <VStack align="start" spacing={2} mb={6} w="100%">
                {plan.features.map((feature, i) => (
                  <Text
                    key={i}
                    fontSize="sm"
                    color={textColor}
                    textAlign="left"
                    w="100%"
                  >
                    • {feature}
                  </Text>
                ))}
              </VStack>

              <Text fontSize="2xl" fontWeight="bold" color={priceColor} mb={6}>
                ₹{plan.price}
              </Text>
              {!isHomePage && (
                <Button
                  onClick={() => onSelectPlan(plan)}
                  isDisabled={isPaymentStarted}
                >
                  Select Plan
                </Button>
              )}
            </Box>
          ))}
      </Flex>
    </Box>
  );
};

export default PaymentPlans;
