import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";

const AIAvatar = ({ isSpeaking, blurAmount }) => {
  const videoRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [error, setError] = useState(null);

  const talkingVideoSrc = "/files/videos/talking-face.mp4";
  const neutralVideoSrc = "/files/videos/normal-face.mp4";

  useEffect(() => {
    let isMounted = true;
    const video = videoRef.current;

    const playVideo = async () => {
      try {
        if (video) {
          video.src = isSpeaking ? talkingVideoSrc : neutralVideoSrc;
          await video.load();
          if (isMounted) {
            await video.play();
            setIsVideoReady(true);
            setError(null);
          }
        }
      } catch (err) {
        console.error("Video playback failed:", err);
        if (isMounted) {
          setError(`Failed to play video: ${err.message}`);
          setIsVideoReady(false);
        }
      }
    };

    playVideo();

    return () => {
      isMounted = false;
      if (video) {
        video.pause();
        video.src = "";
        video.load();
      }
    };
  }, [isSpeaking]);

  const handleCanPlay = () => {
    setIsVideoReady(true);
    setError(null);
  };

  return (
    <Box className="ai-avatar" position="relative">
      {error ? (
        <Text color="red.500">Error: {error}</Text>
      ) : (
        <>
          <Box
            as="video"
            ref={videoRef}
            width={{ base: "95%", lg: "400px" }}
            height={{ base: "95%", lg: "100%" }}
            autoPlay
            mx="auto"
            loop
            muted
            playsInline
            onCanPlay={handleCanPlay}
            filter={blurAmount ? `blur(${blurAmount}px)` : "none"} // Apply blur
            display={isVideoReady ? "block" : "none"}
          >
            <source
              src={isSpeaking ? talkingVideoSrc : neutralVideoSrc}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </Box>
          {!isVideoReady && (
            <Box>
              <Spinner size="lg" />
              <Text>Loading...</Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AIAvatar;
