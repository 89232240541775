import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  useToast,
  Input,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useDispatch } from "react-redux";
import "katex/dist/katex.min.css";
import renderMathInElement from "katex/contrib/auto-render";
import { hitBeApi } from "../../../api/api";
import AIAvatar from "./AiAvatar";
import { FaFileUpload } from "react-icons/fa";
import axios from "axios";
import { serverBaseUrl } from "../../../constants/Constants";
import { getCookieToken } from "../../../utils/utils";

const TalkToAssistantComponent = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en-US"); // Default to English
  const [audio, setAudio] = useState(null); // State to store audio data
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [utterance, setUtterance] = useState(null);
  const initialPromptTimeout = useRef(null);
  const debounceTimeout = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isFileBeingUploaded, setIsFileBeingUploaded] = useState(false);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.error("Browser does not support speech recognition.");
    } else {
      initialPromptTimeout.current = setTimeout(() => {
        if (audio) {
          audio.pause();
          setAudio(null);
        }
        speak(
          "Let's start the mock test. Which topic do you want to practice?"
        );
      }, 2000);
    }

    return () => {
      if (initialPromptTimeout.current) {
        clearTimeout(initialPromptTimeout.current);
      }
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (utterance) {
        window.speechSynthesis.cancel();
      }
      if (audio) {
        audio.pause();
        setAudio(null);
      }
      SpeechRecognition.abortListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (!listening && transcript) {
      debounceTimeout.current = setTimeout(() => {
        sendToApi(transcript);
      }, 1000); // Adjust the delay as needed
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const startRecording = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
    }
    if (audio) {
      audio.pause();
      setAudio(null);
    }
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    SpeechRecognition.startListening({
      continuous: false,
      language: currentLanguage,
    });
  };

  const stopRecording = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  const detectLanguage = (text) => {
    const hindiPattern = /[\u0900-\u097F]/; // Hindi Unicode range
    return hindiPattern.test(text) ? "hi-IN" : "en-US";
  };

  const stopSpeaking = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
    }
    if (audio) {
      audio.pause();
      setAudio(null);
    }
    SpeechRecognition.abortListening();
  };

  // Upload file to the backend
  const uploadFile = async (selectedFile) => {
    if (isFileBeingUploaded) {
      return;
    }
    if (!file && !selectedFile) {
      toast({
        title: "No file selected",
        description: "Please select a file before uploading.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsFileBeingUploaded(true);
      stopSpeaking();
      const formData = new FormData();
      formData.append("file", file || selectedFile);

      const token = getCookieToken();
      const uploadResponse = await axios.post(
        `${serverBaseUrl}/pdf/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      const fileIdRes = uploadResponse?.data?.data?.fileWithImagesResponse;
      setFileId(fileIdRes);
      toast({
        title: "File uploaded",
        description: "file uploaded successfully, you can start mock test.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setIsFileBeingUploaded(false);
    } catch (error) {
      console.error("File upload error: ", error);
      toast({
        title: "File upload error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      setIsFileBeingUploaded(false);
    }
  };

  const sendToApi = async (text) => {
    if (isLoadingData || text.length === 0 || audio !== null) {
      return;
    }
    try {
      const detectedLanguage = detectLanguage(text);
      setCurrentLanguage(detectedLanguage);
      setIsLoadingData(true);

      const response = await hitBeApi(`chat/talk`, "POST", {
        text: text,
        fileId: fileId,
      });
      const responseText = response?.data?.messageContent;
      const responseAudio = response?.data?.audio;
      dispatch({
        type: "SET_VOICE_CONVERSATIONS",
        value: response?.data?.userDatewiseConversationsMap,
      });
      if (responseAudio) {
        setIsLoadingData(false);
        // Convert base64 to binary
        const binaryString = window.atob(responseAudio);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const blob = new Blob([bytes.buffer], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(blob);
        const audioElement = new Audio(audioUrl);
        setAudio(audioElement);
        audioElement.play();
        audioElement.addEventListener("ended", () => {
          setAudio(null);
        });
      } else {
        setIsLoadingData(false);
        await speak(responseText);
      }
    } catch (error) {
      setIsLoadingData(false);
      console.error("Error sending text to API: ", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const renderLatex = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const formatText = (text) => {
    const textStr = String(text);
    const formattedText = textStr
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/\n/g, "<br>"); // New lines

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formattedText;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const speak = async (text) => {
    stopRecording();
    const synth = window.speechSynthesis;
    if (utterance) {
      synth.cancel();
    }

    const renderedText = renderLatex(text); // LaTeX rendering
    const formattedText = formatText(renderedText); // Apply custom formatting
    const newUtterance = new SpeechSynthesisUtterance(formattedText);
    setUtterance(newUtterance);
    newUtterance.lang = currentLanguage;

    newUtterance.onend = () => {
      startRecording();
    };

    newUtterance.onstart = () => {
      if (listening) {
        stopRecording();
      }
    };

    synth.speak(newUtterance);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    uploadFile(selectedFile);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      my={{ base: "10%", lg: "5%" }}
      mx={{ base: "0%", lg: "auto" }}
      width={"100%"}
    >
      <VStack spacing={{ base: 2, lg: 4 }} position="relative">
        {(listening || isLoadingData) && (
          <Text
            position="absolute"
            top="10%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="1"
            color="white"
            bg="rgba(0, 0, 0, 0.5)"
            p={2}
            borderRadius="md"
            fontSize={{ base: "md", lg: "xl" }}
          >
            {listening ? "Listening..." : "Loading Response..."}
          </Text>
        )}

        <AIAvatar
          isSpeaking={audio !== null}
          blurAmount={listening || isLoadingData ? 5 : 0}
        />
        {transcript && (
          <Text mt={4} fontSize={{ base: "small", lg: "lg" }}>
            Transcript: {transcript}
          </Text>
        )}
        <Tooltip
          label={
            isFileBeingUploaded
              ? "Please wait until file upload"
              : "Click to restart"
          }
          shouldWrapChildren
          isDisabled={!isFileBeingUploaded} // Show tooltip only when file is being uploaded
        >
          <Button
            onClick={startRecording}
            fontSize={{ base: "medium", lg: "lg" }}
            isDisabled={isLoadingData || isFileBeingUploaded}
            _hover={isFileBeingUploaded ? { cursor: "not-allowed" } : {}}
          >
            Restart Listening to me
          </Button>
        </Tooltip>

        <Tooltip
          label={
            isFileBeingUploaded
              ? "Please wait until file upload"
              : "Click to stop"
          }
          shouldWrapChildren
          isDisabled={!isFileBeingUploaded} // Show tooltip only when file is being uploaded
        >
          <Button
            onClick={stopSpeaking}
            fontSize={{ base: "medium", lg: "lg" }}
            isDisabled={isLoadingData || isFileBeingUploaded}
            _hover={isFileBeingUploaded ? { cursor: "not-allowed" } : {}}
          >
            Stop Speaking
          </Button>
        </Tooltip>

        <Button
          as="label"
          htmlFor="fileUpload"
          variant="outline"
          leftIcon={<Icon as={FaFileUpload} />}
          size="md"
          height={{ base: "5vh", md: "5vh", lg: "5vh" }}
        >
          Upload File for voice mock test
        </Button>

        <Input
          type="file"
          id="fileUpload"
          display="none"
          onChange={handleFileChange}
        />

        {file && (
          <Text mt={2} fontSize="sm">
            Selected File: {file.name}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default TalkToAssistantComponent;
