import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { getFormattedTime } from "../../../utils/utils";
import renderMathInElement from "katex/contrib/auto-render";
import "katex/dist/katex.min.css";
import { StarIcon } from "@chakra-ui/icons";

// Helper function to format text
const formatText = (text) => {
  const textStr = String(text);
  const formattedText = textStr
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
    .replace(/\n/g, "<br>"); // New lines

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = formattedText;
  renderMathInElement(tempDiv, {
    delimiters: [
      { left: "\\(", right: "\\)", display: false },
      { left: "\\[", right: "\\]", display: true },
    ],
  });
  return tempDiv.innerHTML;
};

const QuestionComponent = ({
  questionText,
  questionPassage,
  questionType,
  questionNumber,
  options,
  images,
  marksPositive,
  marksNegative,
  onPreviousClick,
  onNextClick,
  saveAnswer,
  userAnswer,
  questionTimeSpent,
  bookmarkCurrentQuestion,
  totalExamAttemptTimeRemaining,
  isQuestionBookmarked,
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const backgroundColor = useColorModeValue("white", "gray.700");
  const [selectedOption, setSelectedOption] = useState("");
  const [numericalAnswer, setNumericalAnswer] = useState("");
  const [subjectiveAnswer, setSubjectiveAnswer] = useState("");
  const [passageAnswer, setPassageAnswer] = useState("");
  const [elapsedQuestionTime, setElapsedQuestionTime] =
    useState(questionTimeSpent);
  const elapsedQuestionTimeRef = useRef(questionTimeSpent);
  const saveAnswerDetailsCalled = useRef(false);

  useEffect(() => {
    if (questionType === "objective") {
      setSelectedOption(userAnswer || "");
    } else if (questionType === "numerical") {
      setNumericalAnswer(userAnswer || "");
    } else if (questionType === "subjective") {
      setSubjectiveAnswer(userAnswer || "");
    } else if (questionType === "passage") {
      setPassageAnswer(userAnswer || "");
    }
  }, [questionType, userAnswer]);

  const handleSaveClick = (cause) => {
    if (saveAnswerDetailsCalled.current && cause === "unmount") {
      return;
    }
    saveAnswerDetailsCalled.current = true;
    if (questionType === "objective") {
      saveAnswer(selectedOption, "answered", elapsedQuestionTimeRef.current);
    } else if (questionType === "numerical") {
      saveAnswer(numericalAnswer, "answered", elapsedQuestionTimeRef.current);
    } else if (questionType === "subjective") {
      saveAnswer(subjectiveAnswer, "answered", elapsedQuestionTimeRef.current);
    } else if (questionType === "passage") {
      saveAnswer(passageAnswer, "answered", elapsedQuestionTimeRef.current);
    }
  };

  const handleSaveAndMarkItClick = (cause) => {
    if (saveAnswerDetailsCalled.current && cause === "unmount") {
      return;
    }
    saveAnswerDetailsCalled.current = true;
    if (questionType === "objective") {
      saveAnswer(
        selectedOption,
        "markedAndAnswered",
        elapsedQuestionTimeRef.current
      );
    } else if (questionType === "numerical") {
      saveAnswer(
        numericalAnswer,
        "markedAndAnswered",
        elapsedQuestionTimeRef.current
      );
    } else if (questionType === "subjective") {
      saveAnswer(
        subjectiveAnswer,
        "markedAndAnswered",
        elapsedQuestionTimeRef.current
      );
    } else if (questionType === "passage") {
      saveAnswer(
        passageAnswer,
        "markedAndAnswered",
        elapsedQuestionTimeRef.current
      );
    }
  };

  useEffect(() => {
    if (totalExamAttemptTimeRemaining <= 1) {
      saveAnswer(
        null,
        null,
        elapsedQuestionTimeRef.current,
        totalExamAttemptTimeRemaining
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalExamAttemptTimeRemaining]);

  const handleNextClick = async () => {
    await saveAnswer(null, null, elapsedQuestionTimeRef.current);
    onNextClick();
  };

  const handlePreviousClick = async () => {
    await saveAnswer(null, null, elapsedQuestionTimeRef.current);
    onPreviousClick();
  };

  useEffect(() => {
    const startTime = Date.now();
    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      elapsedQuestionTimeRef.current = elapsed + questionTimeSpent;
      setElapsedQuestionTime(elapsedQuestionTimeRef.current); // Update state to trigger re-render
    }, 1000);

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePreviousClick();
      } else if (event.key === "ArrowRight") {
        handleNextClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      clearInterval(timerInterval);
      window.removeEventListener("keydown", handleKeyDown);
      handleSaveClick("unmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTimeSpent]);

  const letterOptions = ["A", "B", "C", "D"];

  return (
    <Box
      width={{ base: "100vw", lg: "80vw" }}
      border={`1px solid ${borderColor}`}
      borderRadius="8px"
      padding={{ base: "16px", lg: "16px" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      backgroundColor={backgroundColor}
    >
      <Box>
        <Flex alignItems="center" mb="4">
          <Text fontSize="2xl" mr="4">
            Question No. {questionNumber}
          </Text>
          <Tooltip
            label={
              isQuestionBookmarked ? "Remove Bookmark" : "Bookmark Question"
            }
            aria-label="Bookmark tooltip"
          >
            <IconButton
              aria-label="Bookmark"
              icon={<StarIcon />}
              onClick={bookmarkCurrentQuestion}
              variant="outline"
              color={isQuestionBookmarked ? "yellow.400" : "gray.400"} // Color changes based on isBookmarked
              size="lg"
            />
          </Tooltip>
        </Flex>
        <Text fontSize="lg" mb="4">
          {questionText && (
            <span
              dangerouslySetInnerHTML={{ __html: formatText(questionText) }}
            />
          )}
        </Text>
        {questionPassage && (
          <Text fontSize="lg" mb="2" fontWeight={"bold"}>
            Question Passage
          </Text>
        )}
        <Text fontSize="lg" mb="4">
          {questionPassage && (
            <span
              dangerouslySetInnerHTML={{ __html: formatText(questionPassage) }}
            />
          )}
        </Text>
        {images && images.length > 0 && (
          <Stack spacing={4} mb="4">
            {images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`question related image ${index + 1}`}
              />
            ))}
          </Stack>
        )}
        <Text mt="4" fontSize="lg" fontWeight="bold" color="gray.500" mb="4">
          Total Time Spent on Question:{" "}
          <Text as="span" fontWeight="normal" color="gray.400">
            {getFormattedTime(elapsedQuestionTime)}
          </Text>
        </Text>
        {questionType === "objective" && (
          <>
            <RadioGroup
              onChange={(value) => setSelectedOption(value)}
              value={selectedOption}
            >
              <Stack spacing={4}>
                {options.map((option, index) => (
                  <Radio key={index} value={letterOptions[index]}>
                    {option && (
                      <span
                        dangerouslySetInnerHTML={{ __html: formatText(option) }}
                      />
                    )}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            <Text mt="4" fontSize="lg" fontWeight="bold" color="teal.500">
              Selected Answer:{" "}
              <Text as="span" fontWeight="normal" color="gray.400">
                {selectedOption || "None"}
              </Text>
            </Text>
            <Button mt="8" colorScheme="teal" onClick={handleSaveClick}>
              Save Answer
            </Button>
            <Button
              mt="8"
              colorScheme="teal"
              ml={2}
              onClick={handleSaveAndMarkItClick}
            >
              Save And Mark It
            </Button>
          </>
        )}
        {questionType === "numerical" && (
          <>
            <Input
              placeholder="Enter your answer"
              value={numericalAnswer}
              onChange={(e) => setNumericalAnswer(e.target.value)}
            />
            <Text mt="4" fontSize="lg" fontWeight="bold" color="orange.500">
              User Answer:{" "}
              <Text as="span" fontWeight="normal" color="orange.400">
                {numericalAnswer || "None"}
              </Text>
            </Text>
            <Button mt="8" colorScheme="teal" onClick={handleSaveClick}>
              Save Answer
            </Button>
            <Button
              mt="8"
              colorScheme="teal"
              ml={2}
              onClick={handleSaveAndMarkItClick}
            >
              Save And Mark It
            </Button>
          </>
        )}
        {questionType === "subjective" && (
          <>
            <Textarea
              placeholder="Enter your answer"
              value={subjectiveAnswer}
              onChange={(e) => setSubjectiveAnswer(e.target.value)}
              size="lg"
              resize="vertical"
              overflowY="auto"
              whiteSpace="normal"
            />
            <Text mt="4" fontSize="lg" fontWeight="bold" color="orange.500">
              User Answer:{" "}
              <Text as="span" fontWeight="normal" color="orange.400">
                {subjectiveAnswer || "None"}
              </Text>
            </Text>
            <Button mt="8" colorScheme="teal" onClick={handleSaveClick}>
              Save Answer
            </Button>
            <Button
              mt="8"
              colorScheme="teal"
              ml={2}
              onClick={handleSaveAndMarkItClick}
            >
              Save And Mark It
            </Button>
          </>
        )}
        {questionType === "passage" && (
          <>
            <Textarea
              placeholder="Enter your answer"
              value={passageAnswer}
              onChange={(e) => setPassageAnswer(e.target.value)}
              size="lg"
              resize="vertical"
              overflowY="auto"
              whiteSpace="normal"
            />
            <Text mt="4" fontSize="lg" fontWeight="bold" color="orange.500">
              User Answer:{" "}
              <Text as="span" fontWeight="normal" color="orange.400">
                {passageAnswer || "None"}
              </Text>
            </Text>
            <Button mt="8" colorScheme="teal" onClick={handleSaveClick}>
              Save Answer
            </Button>
            <Button
              mt="8"
              colorScheme="teal"
              ml={2}
              onClick={handleSaveAndMarkItClick}
            >
              Save And Mark It
            </Button>
          </>
        )}
        <Box
          mt="8"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>
            Marks: +{marksPositive},{" "}
            {marksNegative > 0 ? -1 * marksNegative : marksNegative}
          </Text>
          <Box>
            <Button onClick={handlePreviousClick} mr="4">
              Previous
            </Button>
            <Button onClick={handleNextClick}>Next</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionComponent;
