import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Text,
  VStack,
  Heading,
  Select,
  Box,
  useToast,
  Input,
  Icon,
  Spinner,
  useDisclosure,
  ModalFooter,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
} from "@chakra-ui/react";
import AiInterviewStart from "./AiInterviewStart";
import AiInterviewAvatar from "./AiInterviewAvatar";
import axios from "axios";
import { getCookieToken } from "../../../utils/utils";
import { serverBaseUrl } from "../../../constants/Constants";
import { FaFileUpload } from "react-icons/fa";
import { hitBeApi } from "../../../api/api";

const AiInterview = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDifficulty, setSelectedDifficulty] = useState("Medium");
  const [resume, setResume] = useState(null);
  const [resumeId, setResumeId] = useState(null);
  const [isResumeBeingUploaded, setIsResumeBeingUploaded] = useState(false);
  const [startInterview, setStartInterview] = useState(false);
  const [numOfQuestions, setNumberOfQuestions] = useState(10);
  const toast = useToast();

  const avatars = [
    {
      id: 1,
      videoPath: "/interviews/videos/normal-face-men1.mp4",
      name: "Alice",
    },
    {
      id: 2,
      videoPath: "/interviews/videos/normal-face-men2.mp4",
      name: "Elok",
    },
    {
      id: 3,
      videoPath: "/interviews/videos/normal-face-men3.mp4",
      name: "Jest",
    },
    {
      id: 4,
      videoPath: "/interviews/videos/normal-face-men4.mp4",
      name: "Nick",
    },
    {
      id: 5,
      videoPath: "/interviews/videos/normal-face-women1.mp4",
      name: "Sophie",
    },
    {
      id: 6,
      videoPath: "/interviews/videos/normal-face-women2.mp4",
      name: "Ken",
    },
  ];

  const domains = [
    "Software Development",
    "Data Science & Analytics",
    "Consulting",
    "Product Management",
    "Finance & Investment Banking",
    "Core Engineering",
    "Operations & Supply Chain",
    "Marketing & Sales",
    "Design & Creativity",
    "Research & Development",
    "Human Resources",
  ];

  const roles = {
    "Software Development": [
      "Software Development Engineer (SDE)",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "DevOps Engineer",
      "Mobile App Developer",
      "Cloud Engineer",
    ],
    "Data Science & Analytics": [
      "Data Scientist",
      "Data Analyst",
      "Business Analyst",
      "ML Engineer",
      "AI Researcher",
      "Quantitative Analyst",
    ],
    Consulting: [
      "Management Consultant",
      "Strategy Consultant",
      "Operations Consultant",
      "Technology Consultant",
      "Risk Analyst",
    ],
    "Product Management": [
      "Associate Product Manager (APM)",
      "Product Manager",
      "Product Strategy Analyst",
      "User Research Analyst",
    ],
    "Finance & Investment Banking": [
      "Investment Banking Analyst",
      "Equity Research Analyst",
      "Risk Management Analyst",
      "Portfolio Manager",
      "Financial Analyst",
      "Trader",
    ],
    "Core Engineering": [
      "Mechanical Design Engineer",
      "Civil Engineer",
      "Electrical Engineer",
      "Chemical Process Engineer",
      "Industrial Engineer",
      "Materials Scientist",
    ],
    "Operations & Supply Chain": [
      "Operations Manager",
      "Supply Chain Analyst",
      "Procurement Specialist",
      "Logistics Manager",
      "Process Improvement Analyst",
    ],
    "Marketing & Sales": [
      "Marketing Manager",
      "Brand Strategist",
      "Digital Marketing Specialist",
      "Sales Executive",
      "Business Development Manager",
      "Account Manager",
    ],
    "Design & Creativity": [
      "UX Designer",
      "UI Designer",
      "Graphic Designer",
      "Motion Graphics Artist",
      "Visual Designer",
      "Product Designer",
    ],
    "Research & Development": [
      "R&D Engineer",
      "Research Scientist",
      "Innovation Specialist",
      "Lab Manager",
      "Academic Research Associate",
    ],
    "Human Resources": [
      "HR Generalist",
      "Recruitment Specialist",
      "Employee Relations Manager",
      "HR Business Partner",
      "Talent Acquisition Specialist",
    ],
  };

  const handleAvatarSelect = (avatar) => setSelectedAvatar(avatar);
  const handleDomainChange = (e) => setSelectedDomain(e.target.value);
  const handleRoleChange = (e) => setSelectedRole(e.target.value);
  const handleDifficultyChange = (e) => setSelectedDifficulty(e.target.value);
  const [existingUploadedResumes, setExistingUploadedResumes] = useState([]);
  const isFetchUploadedResumesApiCalled = useRef(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedExistingResume, setSelectedExistingResume] = useState(null);
  const [company, setCompany] = useState("");

  const handleSelectExistingResume = () => {
    onOpen(); // Open the modal to select an existing file
  };

  const handleConfirmSelection = () => {
    onClose();
  };

  useEffect(() => {
    const getAllExamFilesUploadedDetails = async () => {
      if (isFetchUploadedResumesApiCalled.current) {
        return;
      }
      isFetchUploadedResumesApiCalled.current = true;
      try {
        const response = await hitBeApi("file/interview", "GET");
        setExistingUploadedResumes(response.data);
      } catch (error) {
        console.error("Error fetching uploaded resumes:", error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };
    getAllExamFilesUploadedDetails();
  }, [toast]);

  const uploadResume = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    try {
      setIsResumeBeingUploaded(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("sourceType", "interview");

      const token = getCookieToken();
      const response = await axios.post(
        `${serverBaseUrl}/pdf/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      setResumeId(response.data?.data?.fileWithImagesResponse);
      setResume(selectedFile);

      toast({
        title: "Resume Uploaded",
        description: "Your resume was successfully uploaded.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Resume upload error: ", error);
      toast({
        title: "Upload Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsResumeBeingUploaded(false);
    }
  };

  if (startInterview) {
    return (
      <AiInterviewStart
        avatar={selectedAvatar}
        selectedDifficulty={selectedDifficulty}
        domain={selectedDomain}
        role={selectedRole}
        resumeId={resumeId}
        selectedExistingResume={selectedExistingResume}
        company={company}
        numOfQuestions={numOfQuestions}
      />
    );
  }

  return (
    <VStack
      spacing={8}
      p={6}
      align="center"
      bg="gray.50"
      borderRadius="md"
      boxShadow="lg"
      maxW="800px"
      mx="auto"
      mt={8}
    >
      <Heading as="h1" size="xl" color="teal.600">
        AI Mock Interview
      </Heading>

      {!selectedAvatar ? (
        <>
          <Heading as="h2" size="md" color="gray.700">
            Choose an AI Avatar
          </Heading>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={6}
            mt={4}
          >
            {avatars.map((avatar) => (
              <Box
                key={avatar.id}
                borderWidth={2}
                borderColor={
                  selectedAvatar?.id === avatar.id ? "teal.500" : "gray.300"
                }
                borderRadius="lg"
                p={4}
                textAlign="center"
                cursor="pointer"
                boxShadow={
                  selectedAvatar?.id === avatar.id ? "0 0 10px teal" : "sm"
                }
                _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
                onClick={() => handleAvatarSelect(avatar)}
              >
                <AiInterviewAvatar
                  isSpeaking={false}
                  neutralVideoSrc={avatar.videoPath}
                />
                <Text fontWeight="bold" color="gray.800" mt={2}>
                  {avatar.name}
                </Text>
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Heading as="h2" size="md" color="gray.700">
            Choose Domain and Role
          </Heading>
          <VStack spacing={4} w="100%" maxW="500px">
            <Select
              placeholder="Select Domain"
              onChange={handleDomainChange}
              variant="outline"
              size="md"
              bg="white"
              boxShadow="sm"
              _hover={{ boxShadow: "md" }}
            >
              {domains.map((domain, index) => (
                <option key={index} value={domain}>
                  {domain}
                </option>
              ))}
            </Select>

            <Select
              placeholder="Select Role"
              onChange={handleRoleChange}
              variant="outline"
              size="md"
              bg="white"
              boxShadow="sm"
              _hover={{ boxShadow: "md" }}
              isDisabled={!selectedDomain}
            >
              {roles[selectedDomain]?.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </Select>

            <Select
              placeholder="Select Difficulty"
              onChange={handleDifficultyChange}
              variant="outline"
              size="md"
              bg="white"
              boxShadow="sm"
              _hover={{ boxShadow: "md" }}
            >
              {["Easy", "Medium", "Hard"].map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </Select>
          </VStack>

          {/* Input for company-specific interview */}
          <VStack spacing={4} mt={6} w="100%" maxW="500px">
            <Input
              placeholder="Enter company name (e.g., Google, Amazon)"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              bg="white"
              boxShadow="sm"
              size="md"
              _hover={{ boxShadow: "md" }}
            />
          </VStack>

          {/* Select number of questions */}
          <VStack spacing={4} mt={4} w="100%" maxW="500px">
            <Select
              placeholder="Select Number of Questions"
              onChange={(e) => setNumberOfQuestions(e.target.value)}
              variant="outline"
              size="md"
              bg="white"
              value={numOfQuestions}
              boxShadow="sm"
              _hover={{ boxShadow: "md" }}
            >
              {[5, 10, 15, 20, 25].map((num) => (
                <option key={num} value={num}>
                  {num} Questions
                </option>
              ))}
            </Select>
          </VStack>

          <Box textAlign="center" mt={6}>
            {existingUploadedResumes.length > 0 && (
              <>
                <Button
                  variant="outline"
                  size="md"
                  ml={2}
                  mb={{ base: 2, lg: 0 }}
                  onClick={handleSelectExistingResume}
                  isDisabled={selectedExistingResume}
                >
                  Select Existing File
                </Button>
              </>
            )}
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Select an Existing Resume</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <RadioGroup
                    onChange={(value) =>
                      setSelectedExistingResume(JSON.parse(value))
                    }
                    value={JSON.stringify(selectedExistingResume)}
                  >
                    <VStack align="start">
                      {existingUploadedResumes.map((file, index) => (
                        <Radio key={index} value={JSON.stringify(file)}>
                          {file?.fileName}
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={handleConfirmSelection}
                  >
                    Confirm Selection
                  </Button>
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Button
              as="label"
              htmlFor="fileUpload"
              variant="solid"
              leftIcon={<Icon as={FaFileUpload} />}
              colorScheme="blue"
              size="md"
              ml={{ base: 0, lg: 4 }}
            >
              {isResumeBeingUploaded ? <Spinner size="sm" /> : "Upload Resume"}
            </Button>
            <Input
              id="fileUpload"
              type="file"
              display="none"
              onChange={uploadResume}
            />
            <Text mt={2} fontSize="sm" color="gray.500">
              Uploading a resume is optional. If uploaded, questions will be
              based on your resume.
            </Text>
            {resume && (
              <Text mt={2} fontSize="sm" color="gray.600">
                Selected File: <strong>{resume.name}</strong>
              </Text>
            )}
            {selectedExistingResume && (
              <Text mt={2} fontSize="sm">
                Selected Existing File: {selectedExistingResume.fileName}
              </Text>
            )}
          </Box>

          <Button
            colorScheme="teal"
            size="lg"
            mt={6}
            isDisabled={isResumeBeingUploaded}
            onClick={() => {
              if (!selectedDomain || !selectedRole) {
                toast({
                  title: "Missing Fields",
                  description:
                    "Please complete all required fields before proceeding.",
                  status: "warning",
                  duration: 3000,
                  isClosable: true,
                });
                return;
              }
              setStartInterview(true);
            }}
          >
            Start Interview
          </Button>
        </>
      )}
    </VStack>
  );
};

export default AiInterview;
