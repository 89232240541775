import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Collapse,
  Button,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";

const faqs = [
  {
    question: "What is TryExam AI?",
    answer:
      "TryExam AI is an online platform that provides customized mock tests for various government exams, college entrance exams, and foreign entrance exams using AI-generated question papers. TryExam AI also provides interactive mock interviews jobs , campus placements , internships.",
  },
  {
    question: "How does TryExam AI work?",
    answer:
      "Our platform uses AI to generate mock test papers based on the latest exam patterns and provides detailed analytics on your performance to help you improve. TryExam AI also provides customized interactive mock interviews for all campus placement job profiles and off-campus placements",
  },
  {
    question: "How can I start using TryExam AI?",
    answer:
      "You can start by signing up for a free trial on our homepage. Once registered, you can select the exams you want to prepare for and begin taking mock tests.",
  },
  {
    question: "Are the mock tests available for all exams?",
    answer:
      "We offer mock tests for a wide range of exams, including government exams like UPSC and NDA, college entrance exams like IIT-JEE and NEET, and foreign entrance exams.",
  },
  {
    question: "How do I get my test results?",
    answer:
      "After you complete a mock test, you will receive a detailed report with your scores, analysis of your performance, and feedback on areas where you can improve.",
  },
  {
    question: "Can I get personalized feedback on my performance?",
    answer:
      "Yes, our platform provides detailed feedback on your performance and suggests reading materials to help you improve in topics where you are lacking.",
  },
  {
    question: "Is there a mobile app for TryExam AI?",
    answer:
      "Currently, we offer a web-based platform. However, you can access our site from any device with a web browser, including mobile devices.",
  },
  {
    question: "What is the pricing for TryExam AI?",
    answer:
      "We provide a range of pricing plans, including a free trial. Once your free plan limit is exceeded, we will prompt you for payment.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can reach out to our customer support team through the 'Contact Us' page on our website, or you can email us at support@tryexamai.com.",
  },
  {
    question: "Do you offer a satisfaction guarantee?",
    answer:
      "Yes, we offer a satisfaction guarantee for our paid plans. If you're not satisfied with our service, you can contact us within the trial period for a refund.",
  },
];

const FAQItem = ({ question, answer }) => {
  const { isOpen, onToggle } = useDisclosure();
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Box
      borderWidth={1}
      borderRadius="md"
      borderColor={borderColor}
      p={4}
      mb={2}
      bg={bgColor}
    >
      <Button
        onClick={onToggle}
        variant="outline"
        colorScheme="teal"
        width="100%"
        justifyContent="space-between"
      >
        <Text
          fontWeight="bold"
          color={textColor}
          fontSize={{ base: "x-small", lg: "large" }}
        >
          {question}
        </Text>
        <Text color={textColor}>{isOpen ? "−" : "+"}</Text>
      </Button>
      <Collapse in={isOpen}>
        <Box mt={2}>
          <Text color={textColor} fontSize={{ base: "x-small", lg: "large" }}>
            {answer}
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

const FAQs = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={{ base: 2, lg: 8 }}
      bg={bgColor}
      id="faqs"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <VStack spacing={8} align="center">
        <Heading as="h2" size="xl" mb={4} color={headingColor}>
          Frequently Asked Questions
        </Heading>
        <Text fontSize="lg" textAlign="center" color={textColor}>
          Find answers to the most common questions about Mock Test AI.
        </Text>
        <VStack spacing={4} align="stretch" maxW="800px" w="100%">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default FAQs;
