/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spinner, Text, Button } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";

const AiInterviewAvatar = ({
  isSpeaking,
  talkingVideoSrc,
  neutralVideoSrc,
}) => {
  const videoRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [error, setError] = useState(null);

  const refreshPage = () => {
    window.location.reload();
  };

  const playVideo = async () => {
    const video = videoRef.current;
    try {
      if (video) {
        video.pause(); // Pause any existing playback
        video.src = isSpeaking ? talkingVideoSrc : neutralVideoSrc; // Set the correct source
        setIsVideoReady(false); // Reset ready state
        setError(null); // Clear any previous error
        await video.load(); // Reload the video
        await video.play(); // Play the video
        setIsVideoReady(true); // Set ready state
      }
    } catch (err) {
      console.error("Video playback failed:", err);
      setError(`Failed to play video: ${err.message}`);
      setIsVideoReady(false);
    }
  };

  useEffect(() => {
    playVideo();

    // Cleanup
    return () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
        video.src = "";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpeaking, talkingVideoSrc, neutralVideoSrc]);

  return (
    <Box className="ai-avatar" position="relative">
      {error ? (
        <Box textAlign="center">
          <Text color="red.500" mb={2}>
            Error: {error}
          </Text>
          <Button
            colorScheme="blue"
            onClick={() => {
              playVideo();
              refreshPage();
            }}
          >
            Retry
          </Button>
        </Box>
      ) : (
        <>
          <Box
            as="video"
            ref={videoRef}
            width="100%"
            height={{ base: "200px", lg: "300px" }}
            muted
            autoPlay
            loop
            playsInline
            borderRadius="md"
            shadow="md"
            onCanPlay={() => {
              setIsVideoReady(true);
              setError(null);
            }}
            onError={() => {
              setError("Failed to load or play the video.");
              setIsVideoReady(false);
            }}
            display={isVideoReady ? "block" : "none"}
          >
            <source
              src={isSpeaking ? talkingVideoSrc : neutralVideoSrc}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </Box>

          {!isVideoReady && !error && (
            <Box textAlign="center">
              <Spinner size="lg" />
              <Text>Loading...</Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AiInterviewAvatar;
