import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  HStack,
  Circle,
  useColorModeValue,
  Spinner,
  useToast,
  Divider,
  Input,
  Select,
} from "@chakra-ui/react";
import renderMathInElement from "katex/contrib/auto-render";
import "katex/dist/katex.min.css";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../../api/api";
import { getFormattedTime } from "../../../utils/utils";

const formatText = (text) => {
  const textStr = String(text);
  const formattedText = textStr
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    .replace(/\n/g, "<br>");
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = formattedText;
  renderMathInElement(tempDiv, {
    delimiters: [
      { left: "\\(", right: "\\)", display: false },
      { left: "\\[", right: "\\]", display: true },
    ],
  });
  return tempDiv.innerHTML;
};

const ViewAttempt = ({ attemptId }) => {
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(""); // New state for filter
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const toast = useToast();
  const fetchExamsCalled = useRef(false);
  const dispatch = useDispatch();
  const attemptsMapFromState = useSelector((state) => state.attemptsMap);

  const textColor = useColorModeValue("gray.700", "gray.200");

  useEffect(() => {
    const fetchExamDetails = async () => {
      if (fetchExamsCalled.current) return;
      fetchExamsCalled.current = true;
      try {
        const response = await hitBeApi(`exams/attempt/${attemptId}`);
        if (response.success) {
          setExamData(response.data.attemptDetails);
          toast({
            title: "Exam details retrieved successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          dispatch({
            type: "SET_ALL_ATTEMPTS_MAP",
            value: response.data.attemptDetails,
          });
        } else {
          toast({
            title: "Failed to retrieve exam details.",
            description: response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "An error occurred.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (
      attemptsMapFromState &&
      Object.keys(attemptsMapFromState).length !== 0
    ) {
      if (attemptId) {
        const attempt = attemptsMapFromState[attemptId];
        if (attempt && Object.keys(attempt).length !== 0) {
          setExamData(attempt);
          setLoading(false);
        } else {
          fetchExamDetails();
        }
      }
    } else {
      if (attemptId) {
        fetchExamDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptId]);

  const bg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("black", "white");
  const correctColor = useColorModeValue("green.200", "green.700");
  const incorrectColor = useColorModeValue("red.200", "red.500");
  const selectedColor = useColorModeValue("blue.200", "blue.500");

  if (loading) {
    return <Spinner size="xl" color={selectedColor} />;
  }

  if (!examData) {
    return (
      <Text color={color} textAlign="center">
        Unable to load exam details. Please try again later.
      </Text>
    );
  }

  // Filter and search logic
  const filteredQuestions = examData.sections.flatMap((section) =>
    section.questions.filter((question) => {
      const questionTextMatch = question.questionText
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (!filter) {
        return questionTextMatch;
      } else if (filter === "correct") {
        return (
          (question.answerStatus === "correct" ||
            question.answerStatus === "partiallyCorrect") &&
          questionTextMatch
        );
      } else if (filter === "inCorrect") {
        return question.answerStatus === "inCorrect" && questionTextMatch;
      } else if (filter === "partiallyCorrect") {
        return (
          question.answerStatus === "partiallyCorrect" && questionTextMatch
        );
      } else if (filter === "notAttempted") {
        return question.answerStatus === "notAttempted" && questionTextMatch;
      } else if (filter === "isBookmarked") {
        return (
          question.isBookmarked &&
          question.isBookmarked === true &&
          questionTextMatch
        );
      }
      return questionTextMatch;
    })
  );

  const letterOptions = ["A", "B", "C", "D"];

  return (
    <Box bg={bg} p={{ base: 4, lg: 8 }} borderRadius="md" shadow="lg">
      <Heading
        as="h2"
        size="lg"
        mb={{ base: 4, lg: 8 }}
        textAlign="center"
        color={color}
        textDecoration="underline"
      >
        {examData.examDetails.title} - Attempt Details
      </Heading>

      <HStack mb={8} spacing={4} justifyContent="center">
        <Input
          placeholder="Search question..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          width="300px"
        />
        <Select
          placeholder="Filter by answer"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="correct">Correct</option>
          <option value="inCorrect">Incorrect</option>
          <option value="notAttempted">Not Attempted</option>
          <option value="partiallyCorrect">Partially Correct</option>
          <option value="isBookmarked">Is Bookmarked</option>
        </Select>
      </HStack>

      {filteredQuestions.length > 0 && (
        <Text color={color} textAlign="center" my="15px">
          Total Count : {filteredQuestions.length}
        </Text>
      )}

      {filteredQuestions.length === 0 ? (
        <Text color={color} textAlign="center">
          No questions found matching the criteria.
        </Text>
      ) : (
        filteredQuestions.map((question, questionIndex) => (
          <Box
            key={questionIndex}
            bg={boxBg}
            p={{ base: 4, lg: 8 }}
            borderRadius="md"
            shadow="md"
            mb={{ base: 4, lg: 8 }}
            _hover={{ transform: "scale(1.02)", transition: "0.2s" }}
            border={question.status === "incorrect" ? "2px solid red" : "none"} // Different styling for incorrect answers
          >
            <Heading as="h4" size="md" color={color} mb={2}>
              Question {questionIndex + 1}:
            </Heading>
            {question.questionText && (
              <Text
                fontSize="md"
                color={textColor}
                mb={3}
                dangerouslySetInnerHTML={{
                  __html: formatText(question.questionText),
                }}
              />
            )}
            <Text fontSize="sm" color="gray.600" fontStyle="italic">
              Time Taken: {getFormattedTime(question.time)}
            </Text>
            {question.questionType === "objective" && (
              <VStack align="stretch" mb={4} spacing={3}>
                {question.options.map((option, idx) => (
                  <HStack
                    key={idx}
                    p={3}
                    borderRadius="md"
                    bg={
                      letterOptions[idx] === question.correctAnswer
                        ? correctColor
                        : letterOptions[idx] === question.userAnswer &&
                        letterOptions[idx] !== question.correctAnswer
                        ? incorrectColor
                        : boxBg
                    }
                    color={
                      letterOptions[idx] === question.correctAnswer ||
                      (letterOptions[idx] === question.userAnswer &&
                        letterOptions[idx] !== question.correctAnswer)
                        ? "white"
                        : color
                    }
                    borderWidth={1}
                    borderColor={
                      letterOptions[idx] === question.userAnswer
                        ? selectedColor
                        : "gray.300"
                    }
                  >
                    <Circle
                      size={{ base: "12px", lg: "24px" }}
                      bg={
                        letterOptions[idx] === question.userAnswer &&
                        letterOptions[idx] !== question.correctAnswer
                          ? incorrectColor
                          : letterOptions[idx] === question.userAnswer
                          ? selectedColor
                          : "gray.300"
                      }
                      mr={4}
                    />
                    <Text>
                      {option && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: formatText(option),
                          }}
                        />
                      )}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            )}
            <Divider mb={4} />
            <Box mb={4}>
              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                fontWeight="bold"
                mb={2}
                color={correctColor}
              >
                Correct Answer:
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }} color={correctColor}>
                {question.correctAnswer && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatText(question.correctAnswer),
                    }}
                  />
                )}
              </Text>
            </Box>
            <Box mb={4}>
              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                fontWeight="bold"
                mb={2}
                color={selectedColor}
              >
                Your Answer:
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }} color={selectedColor}>
                {question.userAnswer ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatText(question.userAnswer),
                    }}
                  />
                ) : (
                  "None"
                )}
              </Text>
            </Box>
            <Box mb={4} display={"flex"} flexDirection={"row"}>
              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                fontWeight="bold"
                color={color}
              >
                Positive Marks: {question.positiveMarks}
              </Text>

              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                ml="5%"
                fontWeight="bold"
                color={color}
              >
                Negative Marks: {question.negativeMarks}
              </Text>

              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                fontWeight="bold"
                ml={"5%"}
                color={
                  question.answerStatus === "correct" ||
                  question.answerStatus === "partiallyCorrect"
                    ? correctColor
                    : incorrectColor
                }
              >
                Status:{" "}
                {question.answerStatus?.charAt(0).toUpperCase() +
                  question.answerStatus?.slice(1)}
              </Text>
            </Box>

            <Box mb={4}></Box>
            <Box mb={4}>
              <Text
                fontSize={{ base: "sm", lg: "lg" }}
                fontWeight="bold"
                mb={2}
                color={color}
              >
                Explanation:
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }} color={color}>
                {question.answerExplanation ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatText(question.answerExplanation),
                    }}
                  />
                ) : (
                  "No explanation provided."
                )}
              </Text>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

export default ViewAttempt;
