/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AiInterviewAvatar from "./AiInterviewAvatar";
import { hitBeApi } from "../../../api/api";
import renderMathInElement from "katex/contrib/auto-render";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { getCookieToken } from "../../../utils/utils";
import { serverBaseUrl } from "../../../constants/Constants";
import axios from "axios";

const avatars = [
  {
    id: 1,
    videoPath: "/interviews/videos/talking-face-men1.mp4",
    name: "Alice",
  },
  {
    id: 2,
    videoPath: "/interviews/videos/talking-face-men2.mp4",
    name: "Elok",
  },
  {
    id: 3,
    videoPath: "/interviews/videos/talking-face-men3.mp4",
    name: "Jest",
  },
  {
    id: 4,
    videoPath: "/interviews/videos/talking-face-men4.mp4",
    name: "Nick",
  },
  {
    id: 5,
    videoPath: "/interviews/videos/talking-face-women1.mp4",
    name: "Sophie",
  },
  {
    id: 6,
    videoPath: "/interviews/videos/talking-face-women2.mp4",
    name: "Ken",
  },
];

const AiInterviewStart = ({
  avatar,
  domain,
  company,
  role,
  selectedDifficulty,
  resumeId,
  selectedExistingResume,
  numOfQuestions,
}) => {
  const [isAiSpeaking, setIsAiSpeaking] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const streamRef = useRef(null);
  const videoMediaRecorderRef = useRef(null);
  const audioMediaRecorderRef = useRef(null);
  const [audio, setAudio] = useState(null); // State to store audio data
  const videoChunks = useRef([]); // Separate chunks for video
  const toast = useToast();
  const audioChunks = useRef([]); // Separate chunks for audio  const toast = useToast();
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [interviewId, setInterviewId] = useState("");
  const [utterance, setUtterance] = useState(null);
  const [audioData, setAudioData] = useState(null); // State for audio data
  const [videoData, setVideoData] = useState(null); // State for video data
  const [totalNumberOfQuestion, setTotalNumberOfQuestions] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState("en-US"); // Default to English
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const initialPromptTimeout = useRef(null);
  const debounceTimeout = useRef(null);
  const [isIntroQuestion, setIsIntroQuestion] = useState(true);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [isLoadingInterview, setIsLoadingInterview] = useState(false);
  const [isSendingUserResponse, setIsSendingUserResponse] = useState(false);
  const [isAnalyzingInterview, setIsAnalyzingInterview] = useState(false);
  const [audioResText, setAudioResText] = useState("");
  const [isEditingTranscript, setIsEditingTranscript] = useState(false);
  const [editedTranscript, setEditedTranscript] = useState(transcript);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.error("Browser does not support speech recognition.");
    } else {
      initialPromptTimeout.current = setTimeout(() => {
        if (audio) {
          audio.pause();
          setAudio(null);
        }
      }, 2000);
    }

    return () => {
      if (initialPromptTimeout.current) {
        clearTimeout(initialPromptTimeout.current);
      }
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (utterance) {
        window.speechSynthesis.cancel();
      }
      if (audio) {
        audio.pause();
        setAudio(null);
      }
      SpeechRecognition.abortListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSupportsSpeechRecognition]);

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const startCamera = async (videoRef, audioRef, toast) => {
    try {
      // Request both video and audio streams
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      if (videoRef.current) {
        // Attach the video stream to the video element
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute video audio by default to prevent feedback
      }

      if (audioRef.current) {
        // Attach the audio stream to the audio element
        audioRef.current.srcObject = stream;
        audioRef.current.play(); // Start audio playback
      }

      return stream; // Return the stream for cleanup
    } catch (err) {
      console.error("Failed to access camera or microphone:", err);

      let errorMessage = "Failed to access camera or microphone.";
      if (err.name === "NotAllowedError") {
        errorMessage =
          "Permission denied. Please allow camera and microphone access.";
      } else if (err.name === "NotFoundError") {
        errorMessage =
          "No camera or microphone found. Please connect a device.";
      } else if (err.name === "NotReadableError") {
        errorMessage =
          "Device is already in use. Close other apps using the camera/microphone.";
      }

      toast({
        title: "Camera/Microphone Error",
        description: errorMessage,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const startWebcamVideoRecording = async () => {
    try {
      setIsRecording(true);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      videoMediaRecorderRef.current = new MediaRecorder(stream);

      videoMediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          videoChunks.current.push(event.data);
        }
      };

      videoMediaRecorderRef.current.onstop = async () => {
        // Create a blob containing the recorded data
        const combinedBlob = new Blob(videoChunks.current, {
          type: "video/webm",
        });

        const videoBlob = new Blob(
          [combinedBlob],
          { type: "video/webm; codecs=vp9" } // Ensure video codec compatibility
        );

        console.log("Video Data Length (bytes):", videoBlob.size);

        // Convert Blobs to ArrayBuffer and then to Buffer
        const videoBuffer = await videoBlob.arrayBuffer();

        // Set the states for video in buffer format
        setVideoData(Buffer.from(videoBuffer));

        setIsRecording(false);

        // Clear the chunks for the next recording
        videoChunks.current = [];
      };

      videoMediaRecorderRef.current.start();
    } catch (err) {
      console.error("Failed to start video recording:", err);

      toast({
        title: "Recording Error",
        description: "Unable to start video recording. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const startWebcamAudioRecording = async () => {
    try {
      setIsRecording(true);

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      audioMediaRecorderRef.current = new MediaRecorder(stream);

      audioMediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      audioMediaRecorderRef.current.onstop = async () => {
        // Create a blob containing the recorded data
        const combinedBlob = new Blob(audioChunks.current, {
          type: "audio/webm",
        });

        // Extract audio and video from the combined blob
        const audioBlob = new Blob(
          [combinedBlob],
          { type: "audio/webm; codecs=opus" } // Ensure audio codec compatibility
        );

        console.log("Audio Data Length (bytes):", audioBlob.size);

        // Convert Blobs to ArrayBuffer and then to Buffer
        const audioBuffer = await audioBlob.arrayBuffer();

        // Set the states for audio in buffer format
        setAudioData(Buffer.from(audioBuffer));

        setIsRecording(false);

        // Clear the chunks for the next recording
        audioChunks.current = [];
      };

      audioMediaRecorderRef.current.start();
    } catch (err) {
      console.error("Failed to start audio recording:", err);

      toast({
        title: "Recording Error",
        description: "Unable to start audio recording. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const stopWebcamRecording = async () => {
    setIsRecording(false);
    setIsEditingTranscript(false);
    if (videoMediaRecorderRef.current) {
      videoMediaRecorderRef.current.stop();
    }
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.stop();
    }
    toast({
      title: "Answer Submitted",
      description: "Your answer has been successfully recorded.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    stopAudioRecording();
    await sendUserResponse(editedTranscript);
    resetTranscript();
  };

  const resetResponse = async () => {
    if (videoMediaRecorderRef.current) {
      videoMediaRecorderRef.current.stop();
    }
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    setIsEditingTranscript(false);
    stopAudioRecording();
  };

  const startAudioRecording = async () => {
    // Cancel any ongoing text-to-speech
    if (utterance) {
      window.speechSynthesis.cancel();
    }

    // Pause and reset audio if it's playing
    if (audio) {
      audio.pause();
      setAudio(null);
    }

    // Clear any existing debounce timeouts
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Start speech recognition in continuous mode
    SpeechRecognition.startListening({
      continuous: true, // Enable continuous listening
      language: currentLanguage,
    });
  };

  const stopAudioRecording = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  const renderLatex = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const formatText = (text) => {
    const textStr = String(text);
    const formattedText = textStr
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/\n/g, "<br>"); // New lines

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formattedText;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const speak = async (text) => {
    stopAudioRecording();
    setIsRecording(false);
    const synth = window.speechSynthesis;
    if (utterance) {
      synth.cancel();
    }

    const renderedText = renderLatex(text); // LaTeX rendering
    const formattedText = formatText(renderedText); // Apply custom formatting
    const newUtterance = new SpeechSynthesisUtterance(formattedText);
    setUtterance(newUtterance);

    // Set isAiSpeaking to false once speaking is complete
    newUtterance.onend = () => {
      setIsAiSpeaking(false);
    };

    newUtterance.lang = currentLanguage;
    synth.speak(newUtterance);
  };

  const detectLanguage = (text) => {
    const hindiPattern = /[\u0900-\u097F]/; // Hindi Unicode range
    return hindiPattern.test(text) ? "hi-IN" : "en-US";
  };

  const sendUserResponse = async (text) => {
    if (isSendingUserResponse) {
      return;
    }
    if (!interviewId) {
      toast({
        title: "Error",
        description: "Interview not found, please refresh the tab",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      const detectedLanguage = detectLanguage(text);
      setCurrentLanguage(detectedLanguage);
      setIsSendingUserResponse(true);

      // Create a FormData object
      const formData = new FormData();

      // Create blobs for audio and video
      const audioBlob = new Blob([audioData], { type: "audio/webm" });
      const videoBlob = new Blob([videoData], { type: "video/webm" });

      // Append binary data as Blobs to FormData
      formData.append("audioBuffer", audioBlob, "audio.webm");
      formData.append("video", videoBlob, "video.webm");

      // Append other fields
      formData.append("userResText", text);
      formData.append("interviewId", interviewId);
      formData.append("audioBrowser", audio);
      formData.append("isIntroQuestion", isIntroQuestion);
      formData.append("questionId", currentQuestionId);

      const token = getCookieToken();
      const response = await axios.post(
        `${serverBaseUrl}/interview/ongoing/next`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // Extract data from response
      const {
        message,
        audioRes,
        nextQuestion,
        isCompleted,
        currentQuestionNumber,
      } = response?.data?.data;
      if (nextQuestion) {
        const { questionText } = nextQuestion;
        setAudioResText(questionText);
      }
      setIsAiSpeaking(true);
      setIsIntroQuestion(false);

      if (isCompleted) {
        if (audioRes) {
          setIsSendingUserResponse(false);
          // Convert base64 to binary
          const binaryString = window.atob(audioRes);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob from the binary data
          const blob = new Blob([bytes.buffer], { type: "audio/mp3" });
          const audioUrl = URL.createObjectURL(blob);
          const audioElement = new Audio(audioUrl);
          audioElement.play();
          // Wait for the audio to finish playing
          audioElement.addEventListener("ended", () => {
            setIsAiSpeaking(false);
          });
        } else {
          await speak(message);
        }
        setIsAnalyzingInterview(true);
        await hitBeApi(`interview/analytics/${interviewId}`, "GET");
        navigate("/dashboard/ai-interview-analytics");
      } else {
        const { id, questionText } = nextQuestion;
        setCurrentQuestionId(id);
        setCurrentQuestionIndex(currentQuestionNumber);

        if (audioRes) {
          setIsSendingUserResponse(false);
          // Convert base64 to binary
          const binaryString = window.atob(audioRes);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob from the binary data
          const blob = new Blob([bytes.buffer], { type: "audio/mp3" });
          const audioUrl = URL.createObjectURL(blob);
          const audioElement = new Audio(audioUrl);
          audioElement.play();
          // Wait for the audio to finish playing
          audioElement.addEventListener("ended", () => {
            setIsAiSpeaking(false);
          });
        } else {
          await speak(questionText);
        }
      }
    } catch (error) {
      setIsSendingUserResponse(false);
      console.error("Error sending user response to API: ", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsSendingUserResponse(false);
    }
  };

  // Inside AiInterviewStart
  useEffect(() => {
    const generateInterview = async () => {
      if (isMounted.current) return; // Prevent multiple calls
      isMounted.current = true; // Mark as called

      try {
        setIsLoadingInterview(true);

        const response = await hitBeApi(`interview/generate`, "POST", {
          aiAvatarName: avatar.name,
          domain: domain,
          role: role,
          company: company,
          difficulty: selectedDifficulty,
          fileId: resumeId || selectedExistingResume?._id,
          numOfQuestions: numOfQuestions,
        });

        if (response?.data) {
          const {
            interviewId,
            audio,
            audioText,
            isIntroQuestionRes,
            currentQuestionId,
            currentQuestionNumber,
            totalQuestions,
          } = response.data;
          setAudioResText(audioText);
          setCurrentQuestionIndex(currentQuestionNumber);
          setTotalNumberOfQuestions(totalQuestions);
          if (isIntroQuestionRes !== undefined && isIntroQuestionRes !== null) {
            setIsIntroQuestion(isIntroQuestionRes);
            setCurrentQuestionId(currentQuestionId);
          }
          setInterviewId(interviewId);
          setIsAiSpeaking(true);

          toast({
            title: "Interview Generated",
            description: "Your interview has been generated successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          if (audio) {
            const binaryString = window.atob(audio);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes.buffer], { type: "audio/mp3" });
            const audioUrl = URL.createObjectURL(blob);
            const audioElement = new Audio(audioUrl);
            audioElement.play();
            // Wait for the audio to finish playing
            audioElement.addEventListener("ended", () => {
              setIsAiSpeaking(false);
            });
          } else if (audioText) {
            await speak(audioText);
          }
        }
      } catch (error) {
        console.error("Error generating interview: ", error);
        toast({
          title: "Error",
          description: error.message || "Failed to generate the interview.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingInterview(false);
      }
    };

    generateInterview();

    return () => {
      isMounted.current = true; // Cleanup for Strict Mode
    };
  }, []); // Empty dependency ensures this runs only once

  useEffect(() => {
    const initializeMedia = async () => {
      const stream = await startCamera(videoRef, audioRef, toast);
      streamRef.current = stream; // Store the stream for cleanup
    };

    initializeMedia();

    return () => {
      // Cleanup: Stop all tracks when the component unmounts
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null; // Detach the video stream
      }

      if (audioRef.current) {
        audioRef.current.srcObject = null; // Detach the audio stream
      }
      // Cancel any ongoing text-to-speech
      if (utterance) {
        window.speechSynthesis.cancel();
      }

      // Pause and reset audio if it's playing
      if (audio) {
        audio.pause();
        setAudio(null);
      }

      stopAudioRecording();
    };
  }, [toast]);

  useEffect(() => {
    setEditedTranscript(transcript);
  }, [transcript]);

  return (
    <Box
      bgGradient="linear(to-b, blue.100, white)"
      minH="100vh"
      py={4}
      px={4}
      position="relative"
    >
      {/* Conditional Loading Message */}
      {(isLoadingInterview ||
        isSendingUserResponse ||
        isAnalyzingInterview) && (
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          bg="rgba(0, 0, 0, 0.8)"
          color="white"
          zIndex={10}
          p={24}
          textAlign="center"
        >
          <Flex align="center" justify="center" gap={4}>
            <Spinner size="lg" color="white" />
            <Text fontSize="2xl" fontWeight="bold">
              {isLoadingInterview &&
                "We are fetching your interview details, please wait"}
              {isSendingUserResponse &&
                "We are fetching interviewer response, please wait"}
              {isAnalyzingInterview &&
                "We are analyzing your interview attempt, please wait"}
            </Text>
          </Flex>
        </Box>
      )}

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
        gap={4}
        p={4}
        maxW="1200px"
        mx="auto"
        mt={4}
      >
        {/* Top Left: User Guidance */}
        <GridItem colSpan={1}>
          <Box
            bg="yellow.100"
            border="2px solid"
            borderColor="yellow.500"
            borderRadius="md"
            boxShadow="md"
            p={4}
            textAlign="center"
          >
            <Text fontSize="md" fontWeight="bold" color="yellow.700">
              Please talk slowly for a correct transcript.
            </Text>
            <Text fontSize="md" fontWeight="bold" color="yellow.700">
              Please stay on this tab, else you will need to retry for video.
            </Text>
          </Box>
        </GridItem>

        {/* Top Right: Current Question */}
        <GridItem colSpan={1}>
          <Box
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="2xl"
            textAlign="center"
          >
            <VStack spacing={2}>
              {currentQuestionIndex !== null && (
                <Text fontSize="2xl" fontWeight="extrabold" color="teal.700">
                  Current Question:{" "}
                  <Text as="span" color="teal.500">
                    {currentQuestionIndex === 0
                      ? "Introduction"
                      : currentQuestionIndex === -1
                      ? "No Question"
                      : currentQuestionIndex}
                  </Text>
                </Text>
              )}
              {totalNumberOfQuestion && (
                <Text fontSize="lg" color="gray.700">
                  Total Number Of Questions:{" "}
                  <Text as="span" fontWeight="bold" color="gray.900">
                    {totalNumberOfQuestion}
                  </Text>
                </Text>
              )}
            </VStack>
          </Box>
        </GridItem>

        {/* Bottom Left: AI Audio Response */}
        {audioResText && (
          <GridItem colSpan={1}>
            <Box
              bg="white"
              border="2px solid"
              borderColor="teal.500"
              borderRadius="lg"
              boxShadow="xl"
              p={6}
              textAlign="center"
            >
              <Text fontSize="lg" fontWeight="bold" color="teal.700" mb={4}>
                AI Interviewer Question:
              </Text>
              <Text fontSize="xl" color="gray.800">
                {audioResText}
              </Text>
            </Box>
          </GridItem>
        )}

        {/* Bottom Right: Transcript */}
        {transcript && (
          <GridItem colSpan={1}>
            <Box
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
            >
              <Text fontSize={{ base: "sm", lg: "md" }} color="gray.800" mb={4}>
                <strong>Transcript:</strong> {editedTranscript}
              </Text>
              <Button
                colorScheme="teal"
                size="sm"
                onClick={() => {
                  setIsEditingTranscript(true);
                  setEditedTranscript(transcript);
                }}
                isDisabled={isEditingTranscript}
              >
                Edit Transcript or Add More Info
              </Button>

              {isEditingTranscript && (
                <Box mt={4}>
                  <Textarea
                    value={editedTranscript}
                    onChange={(e) => setEditedTranscript(e.target.value)}
                    size="sm"
                  />
                  <Flex justifyContent="center" mt={2}>
                    <Button
                      colorScheme="green"
                      size="sm"
                      mr={2}
                      onClick={() => {
                        setIsEditingTranscript(false);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => setIsEditingTranscript(false)}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Box>
              )}
            </Box>
          </GridItem>
        )}
      </Grid>

      <Flex
        direction={{ base: "column", lg: "row" }}
        justify="space-around"
        align="center"
        mt={1}
        p={6}
        gap={8}
      >
        {/* Left: AI Avatar */}
        <VStack
          w={{ base: "100%", lg: "45%" }}
          align="center"
          bg="white"
          p={6}
          borderRadius="lg"
          boxShadow="xl"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{ transform: "scale(1.05)", boxShadow: "2xl" }}
        >
          <Text fontSize="lg" fontWeight="bold" color="teal.700">
            AI Interviewer
          </Text>
          <AiInterviewAvatar
            isSpeaking={isAiSpeaking}
            talkingVideoSrc={avatars[avatar.id - 1]?.videoPath}
            neutralVideoSrc={avatar.videoPath}
          />
          <Flex gap={4} mt={6}>
            {!isRecording ? (
              <Button
                colorScheme="teal"
                size={{ base: "sxm", lg: "md" }}
                p={2}
                onClick={async () => {
                  await startAudioRecording();
                  await startWebcamVideoRecording();
                  await startWebcamAudioRecording();
                }}
                isDisabled={
                  isSendingUserResponse ||
                  isLoadingInterview ||
                  isAiSpeaking ||
                  isAnalyzingInterview
                }
              >
                Start Answering
              </Button>
            ) : (
              <Button
                colorScheme="red"
                onClick={stopWebcamRecording}
                size={{ base: "sm", lg: "md" }}
                p={2}
              >
                Submit Answer
              </Button>
            )}
            <Button
              colorScheme="red"
              size={{ base: "sm", lg: "md" }}
              onClick={resetResponse}
              isDisabled={!isRecording}
            >
              Clear Answer
            </Button>
          </Flex>
        </VStack>

        {/* Right: User's Camera */}
        <VStack
          w={{ base: "100%", lg: "45%" }}
          align="center"
          bg="white"
          p={6}
          borderRadius="lg"
          boxShadow="xl"
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{ transform: "scale(1.05)", boxShadow: "2xl" }}
        >
          <Text fontSize="lg" fontWeight="bold" color="teal.700">
            Your Camera
          </Text>
          <Box
            as="video"
            ref={videoRef}
            autoPlay
            muted
            playsInline
            borderWidth="2px"
            borderColor="teal.500"
            borderRadius="md"
            boxSize={{ base: "250px", lg: "350px" }}
          />
        </VStack>
      </Flex>
    </Box>
  );
};

export default AiInterviewStart;
