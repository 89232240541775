import React from "react";
import {
  Box,
  VStack,
  HStack,
  Link,
  Text,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaEnvelope,
  FaTelegramPlane,
} from "react-icons/fa";

const Footer = () => {
  return (
    <Box bg="gray.800" color="white" py={10} px={8}>
      <VStack spacing={6} align="center">
        {/* Main Content */}
        <HStack
          spacing={{ base: 12, lg: 36 }}
          wrap="wrap"
          justify="space-between"
        >
          {/* Navigation Links */}
          <VStack align="start" spacing={4}>
            <Text fontWeight="bold" fontSize="lg">
              Navigation
            </Text>
            <Link href="/privacy-policy" fontSize="md">
              Privacy Policy
            </Link>
            <Link href="/terms-and-conditions" fontSize="md">
              Terms of Service
            </Link>
            <Link href="/contact-us" fontSize="md">
              Contact Us
            </Link>
            <Link href="/faqs" fontSize="md">
              FAQs
            </Link>
          </VStack>
          {/* Social Media Links */}
          <VStack align="start" spacing={2}>
            <Text fontWeight="bold" fontSize="lg">
              Follow Us
            </Text>
            <HStack spacing={1}>
              <IconButton
                as="a"
                href="https://facebook.com"
                aria-label="Facebook"
                icon={<FaFacebookF />}
                variant="link"
                color="white"
                _hover={{ color: "teal.300" }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                as="a"
                href="https://twitter.com"
                aria-label="Twitter"
                icon={<FaTwitter />}
                variant="link"
                color="white"
                _hover={{ color: "teal.300" }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                as="a"
                href="https://instagram.com"
                aria-label="Instagram"
                icon={<FaInstagram />}
                variant="link"
                color="white"
                _hover={{ color: "teal.300" }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                as="a"
                href="https://www.linkedin.com/company/tryexamai/?viewAsMember=true"
                aria-label="LinkedIn"
                icon={<FaLinkedinIn />}
                variant="link"
                color="white"
                _hover={{ color: "teal.300" }}
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                as="a"
                href="https://t.me/+1S_VcMOGw8E1ZTI9" // Replace with your Telegram channel link
                aria-label="Telegram"
                icon={<FaTelegramPlane />}
                variant="link"
                color="white"
                _hover={{ color: "teal.300" }}
                target="_blank"
                rel="noopener noreferrer"
              />
            </HStack>
          </VStack>
          {/* Contact Information */}
          <VStack align="start" spacing={4}>
            <Text fontWeight="bold" fontSize="lg">
              Contact Us
            </Text>
            <HStack spacing={2}>
              <FaEnvelope color="white" />
              <Link href="mailto:support@tryexamai.com" fontSize="md">
                support@tryexamai.com
              </Link>
            </HStack>

            <Text fontSize="md">Jhunjunun, Rajasthan (332746)</Text>
          </VStack>
        </HStack>
        <Divider borderColor="gray.600" width="full" my={6} />
        {/* Footer Bottom */}
        <Text fontSize="sm">
          © {new Date().getFullYear()} Ingenuity Software Solutions Pvt. Ltd.
          All Rights Reserved.
        </Text>
      </VStack>
    </Box>
  );
};

export default Footer;
