import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Spacer,
  Input,
  Select,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatDate, getFormattedTime } from "../../../utils/utils";
import { useSelector } from "react-redux";
import ViewAttempt from "./ViewAttempt";

const AllAttemptsDone = () => {
  const attemptsFromState = useSelector((state) => state.attempts);
  const [searchColumn, setSearchColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [filteredExamAttempts, setFilteredExamAttempts] =
    useState(attemptsFromState);
  const [selectedExamAttempt, setSelectedExamAttempt] = useState(null);

  const bg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const borderColor = useColorModeValue("blue.200", "blue.600");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.400");

  useEffect(() => {
    const lowercasedFilter = searchValue.toLowerCase();
    const newFilteredExams = attemptsFromState.filter((exam) => {
      if (searchColumn === "title") {
        return exam.title?.toLowerCase().includes(lowercasedFilter);
      } else if (searchColumn === "createdAt") {
        return new Date(exam.createdAt)
          .toLocaleDateString()
          .includes(lowercasedFilter);
      }
      return true;
    });
    setFilteredExamAttempts(newFilteredExams);
  }, [searchColumn, searchValue, attemptsFromState]);

  const handleViewAttempt = (exam) => {
    setSelectedExamAttempt(exam);
  };

  const calculateUserMarks = (sections) => {
    const totalMarks = sections.reduce((acc, section) => {
      const sectionMarks = section.questions.reduce((sum, question) => {
        if (
          question.answerStatus === "correct" ||
          question.answerStatus === "partiallyCorrect"
        ) {
          // Add userMarks if positive
          return sum + (question.userMarks > 0 ? question.userMarks : 0);
        } else if (question.answerStatus === "inCorrect") {
          // Subtract userMarks if positive, else add them if zero or negative
          return question.userMarks > 0
            ? sum - question.userMarks
            : sum + question.userMarks;
        }
        return sum;
      }, 0);

      return acc + sectionMarks;
    }, 0);

    return Math.floor(totalMarks);
  };

  const calculateTotalQuestions = (sections) => {
    return sections.reduce((total, section) => {
      return total + (section.questions ? section.questions.length : 0);
    }, 0);
  };

  return (
    <Box
      bg={bg}
      px={{ base: 2, md: 12, lg: 6 }}
      borderRadius="md"
      shadow="md"
      pt={4}
      pb={4}
      mt={{ base: 4, lg: 0 }}
    >
      {selectedExamAttempt ? (
        <ViewAttempt attemptId={selectedExamAttempt._id} />
      ) : (
        <>
          <Heading as="h2" size={{ base: "sm", lg: "lg" }} mb={6} mt={2}>
            All Mock Timed Tests Attempted so far
          </Heading>
          <Flex mb={4} gap={{ base: 1, lg: 4 }}>
            <Select
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
              width="200px"
            >
              <option value="title">Title</option>
              <option value="createdAt">Attempt Date</option>
            </Select>
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Flex>

          <VStack spacing={4} align="stretch" overflowY={"scroll"}>
            {filteredExamAttempts.length === 0 && (
              <Text
                fontSize="large"
                fontWeight="bold"
                mt={{ base: 2, lg: 4 }}
                mx="auto"
                px="5%"
              >
                No attempts found
              </Text>
            )}
            {filteredExamAttempts.map((attempt, index) => (
              <Box
                key={index}
                p={2}
                bg={boxBg}
                borderRadius="md"
                shadow="sm"
                _hover={{ shadow: "md" }}
              >
                <Flex
                  alignItems="center"
                  bg={bgColor}
                  p={3}
                  borderRadius="md"
                  boxShadow="md"
                  border="1px solid"
                  borderColor={borderColor}
                  width="100%"
                  m="auto"
                >
                  <Box p={2}>
                    <Text
                      fontSize={{ base: "sm", lg: "md" }}
                      fontWeight="semibold"
                      color={textColor}
                    >
                      {attempt?.examDetails?.title}
                    </Text>
                    <Text
                      color={secondaryTextColor}
                      fontSize={{ base: "xs", lg: "sm" }}
                    >
                      Attempted on: {formatDate(attempt.createdAt)}
                    </Text>
                    <Text color={textColor} fontSize={{ base: "xs", lg: "sm" }}>
                      Marks: {calculateUserMarks(attempt?.sections)} /{" "}
                      {attempt?.examDetails?.totalMarks}
                    </Text>
                    <Text color={textColor} fontSize={{ base: "xs", lg: "sm" }}>
                      Time Taken: {getFormattedTime(attempt.time)} /{" "}
                      {getFormattedTime(attempt?.allowedTime)}
                    </Text>
                    <Text color={textColor} fontSize={{ base: "xs", lg: "sm" }}>
                      Total Questions:{" "}
                      {calculateTotalQuestions(attempt?.sections)}
                    </Text>
                  </Box>
                  <Spacer />
                  <Button
                    colorScheme="blue"
                    onClick={() => handleViewAttempt(attempt)}
                    size={{ base: "sm", lg: "md" }}
                    fontSize={{ base: "small", lg: "md" }}
                    px={{ base: 6, lg: 6 }}
                    ml={2}
                  >
                    View
                  </Button>
                </Flex>
              </Box>
            ))}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default AllAttemptsDone;
