import React, { useRef, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { Link, useLocation } from "react-router-dom";
import { serverBaseUrl } from "../../../constants/Constants";
import { getCookieToken } from "../../../utils/utils";

const EvaluatePdf = () => {
  const [files, setFiles] = useState({ file1: null, file2: null, file3: null });
  const [fileNames, setFileNames] = useState({
    file1: "",
    file2: "",
    file3: "",
  });
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState(1);
  const [section, setSection] = useState(null);
  const toast = useToast();
  const inputFileRefs = [useRef(null), useRef(null), useRef(null)];
  const linkHoverBg = useColorModeValue("gray.100", "gray.700");

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const fileKey = `file${index + 1}`;
    if (file) {
      const newFiles = { ...files };
      newFiles[fileKey] = file;
      setFiles(newFiles);

      const newFileNames = { ...fileNames };
      newFileNames[fileKey] = file.name;
      setFileNames(newFileNames);

      // Disable file input after selection
      event.target.disabled = true;
    }
  };

  const uploadFile = async (
    file,
    endpoint,
    uniqueFeId,
    fileType,
    filesCount
  ) => {
    const token = getCookieToken();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uniqueFeId", uniqueFeId);
    formData.append("fileType", fileType);
    formData.append("filesCount", filesCount);

    try {
      const response = await axios.post(
        `${serverBaseUrl}/pdf/${endpoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.fileResponse;
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const uploadAndEvaluateFiles = async () => {
    const uniqueFeId = uuidv4().slice(0, 10);
    try {
      setLoading(true);

      if (files.file1 && files.file2 && files.file3) {
        await Promise.all([
          uploadFile(files.file1, "text", uniqueFeId, "question", 3),
          uploadFile(files.file2, "text", uniqueFeId, "adminCorrectAnswer", 3),
          uploadFile(files.file3, "text", uniqueFeId, "studentAnswer", 3),
        ]);
      } else if (files.file1 && files.file3) {
        await Promise.all([
          uploadFile(files.file1, "text", uniqueFeId, "question", 2),
          uploadFile(files.file3, "text", uniqueFeId, "studentAnswer", 2),
        ]);
      } else if (files.file1) {
        await uploadFile(files.file1, "text", uniqueFeId, "questionAnswer", 1);
      } else {
        toast({
          title: "Files missing",
          description: "Please upload all required files!",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setScreen(3);
    } catch (error) {
      console.error("Error in upload and evaluate file:", error);
      toast({
        title: "Upload and evaluate file failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setFiles({ file1: null, file2: null, file3: null });
      setLoading(false);
    }
  };

  const renderUploadSection = () => {
    switch (section) {
      case 1:
        return (
          <>
            <UploadBox label="Upload 1 Questions PDF" index={0} />
            <UploadBox label="Upload 1 Correct Answers PDF" index={1} />
            <UploadBox label="Upload 1 Student Answers PDF" index={2} />
          </>
        );
      case 2:
        return (
          <>
            <UploadBox label="Upload 1 Questions PDF" index={0} />
            <UploadBox label="Upload 1 Student Answers PDF" index={2} />
          </>
        );
      case 3:
        return <UploadBox label="Upload 1 Questions + Answers PDF" index={0} />;
      default:
        return null;
    }
  };

  const UploadBox = ({ label, index }) => (
    <Box
      p={6}
      borderRadius="md"
      mx={"auto"}
      my={2}
      fontSize={{ base: "xx-small", lg: "larger" }}
      textAlign="center"
      boxShadow="md"
      border="1px solid"
      borderColor="gray.200"
    >
      <input
        type="file"
        ref={inputFileRefs[index]}
        style={{ display: "none" }}
        onChange={(event) => handleFileChange(event, index)}
        disabled={!!files[`file${index + 1}`]} // Disable if file is already selected
      />
      <Button
        onClick={() => inputFileRefs[index].current.click()}
        mt={4}
        size={{ base: "sm", lg: "lg" }}
        disabled={!!files[`file${index + 1}`]}
        colorScheme="teal"
      >
        {label}
      </Button>
      {fileNames[`file${index + 1}`] && (
        <Text mt={2} fontSize="sm" color="gray.600">
          Selected file: {fileNames[`file${index + 1}`]}
        </Text>
      )}
    </Box>
  );

  const refreshPage = () => {
    window.location.reload();
  };

  const renderScreen = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        w={"100%"}
      >
        {screen === 3 && (
          <Button
            variant="ghost"
            colorScheme="teal"
            size="lg"
            justifyContent="flex-start"
            _hover={{ bg: linkHoverBg }}
            isActive={isActive("/evaluate-pdf-results")}
            onClick={refreshPage}
          >
            <Link to="/dashboard/evaluate-pdf-results">View Results</Link>
          </Button>
        )}

        {screen === 1 && (
          <VStack spacing={3} my={{ base: "0%", md: "2%" }} mx="2%">
            <Button
              onClick={() => {
                setSection(1);
                setScreen(2);
              }}
              colorScheme="blue"
              size="lg"
              h="auto"
              w={{ base: "95%", md: "100%" }}
              textAlign="left"
              p={6}
              borderRadius="xl"
              boxShadow="md"
              _hover={{
                bg: "blue.600",
                color: "white",
                transform: "scale(1.05)",
              }}
              _active={{ bg: "blue.700", transform: "scale(1.05)" }}
              transition="all 0.5s ease"
            >
              <VStack align="start" spacing={4}>
                <Heading fontSize={{ base: "lg", md: "xl" }} color="blue.900">
                  Upload 3 PDFs for Evaluation
                </Heading>
                <Box>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> <strong>PDF 1:</strong> Questions with marks for
                    each question.
                  </Text>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> <strong>PDF 2:</strong> Correct answers with
                    question numbers.
                  </Text>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> <strong>PDF 3:</strong> Student answers with
                    question numbers
                  </Text>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color="gray.700"
                    mt={2}
                  >
                    Evaluation based on correct answers in PDF 2.
                  </Text>
                </Box>
              </VStack>
            </Button>

            <Button
              onClick={() => {
                setSection(2);
                setScreen(2);
              }}
              colorScheme="blue"
              size="lg"
              h="auto"
              w={{ base: "90%", md: "100%" }}
              textAlign="left"
              p={6}
              borderRadius="xl"
              boxShadow="md"
              _hover={{
                bg: "blue.600",
                color: "white",
                transform: "scale(1.05)",
              }}
              _active={{ bg: "blue.700", transform: "scale(1.05)" }}
              transition="all 0.5s ease"
            >
              <VStack align="start" spacing={4}>
                <Heading fontSize={{ base: "lg", md: "xl" }} color="blue.900">
                  Upload 2 PDFs for Evaluation
                </Heading>
                <Box>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> <strong>PDF 1:</strong> Questions with marks for
                    each question.
                  </Text>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> <strong>PDF 2:</strong> Student answers with
                    question numbers.
                  </Text>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color="gray.700"
                    mt={2}
                  >
                    Evaluation based on correct answers provided by AI
                  </Text>
                </Box>
              </VStack>
            </Button>

            <Button
              onClick={() => {
                setSection(3);
                setScreen(2);
              }}
              colorScheme="blue"
              size="lg"
              h="auto"
              w={{ base: "90%", md: "100%" }}
              textAlign="left"
              p={6}
              borderRadius="xl"
              boxShadow="md"
              _hover={{
                bg: "blue.600",
                color: "white",
                transform: "scale(1.05)",
              }}
              _active={{ bg: "blue.700", transform: "scale(1.05)" }}
              transition="all 0.5s ease"
            >
              <VStack align="start" spacing={4}>
                <Heading fontSize={{ base: "lg", md: "xl" }} color="blue.900">
                  Upload 1 PDF for Evaluation
                </Heading>
                <Box>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> Questions + Answers(marks for each question)
                  </Text>
                  <Text fontSize={{ base: "xs", md: "sm" }} color="gray.700">
                    <b>•</b> Evaluation based on provided answers by AI
                  </Text>
                </Box>
              </VStack>
            </Button>
          </VStack>
        )}
        {screen === 2 && (
          <Box
            maxW={{ base: "90%", lg: "40%" }}
            mx="auto"
            mt="5%"
            textAlign={"center"}
          >
            {renderUploadSection()}
            <Box mt={4}>
              <Button
                onClick={uploadAndEvaluateFiles}
                colorScheme="blue"
                isLoading={loading}
                w={{ base: "90%", lg: "60%" }}
                loadingText="Uploading and evaluating..."
              >
                Upload and Evaluate
              </Button>
              {loading && (
                <Box mt={2} color="gray.500">
                  Please wait, we are generating a response...
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Heading
        as="h1"
        size="2xl"
        textAlign="center"
        mb={6}
        mt={12}
        mx="auto"
        color={useColorModeValue("teal.600", "teal.300")}
      >
        Evaluate PDFs
      </Heading>
      {renderScreen()}
    </Box>
  );
};

export default EvaluatePdf;
