import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Stack,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckCircleIcon, StarIcon } from "@chakra-ui/icons";

const AboutUs = () => {
  // Define color modes for background and text
  const bgColor = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("teal.500", "teal.300");
  const featureBoxBg = useColorModeValue("gray.100", "gray.700");
  const featureTextColor = useColorModeValue("gray.700", "gray.300");
  const specialBoxBg = useColorModeValue("blue.100", "blue.700");
  const specialIconColor = useColorModeValue("blue.500", "blue.300");
  const specialTextColor = useColorModeValue("blue.700", "blue.300");
  const moreFeaturesBoxBg = useColorModeValue("purple.100", "purple.700");
  const moreFeaturesIconColor = useColorModeValue("purple.500", "purple.300");
  const moreFeaturesTextColor = useColorModeValue("purple.700", "purple.300");

  return (
    <Box py={12} px={4} bg={bgColor} id="about">
      <VStack spacing={8} align="center">
        <Heading as="h2" size="xl" color={headingColor}>
          About TryExam AI
        </Heading>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          mt={6}
        >
          <Box flex="1">
            <Image
              src="https://images.pexels.com/photos/4145195/pexels-photo-4145195.jpeg"
              alt="About TryExam AI"
              borderRadius="md"
              boxShadow="md"
              maxH="400px"
              objectFit="cover"
            />
          </Box>
          <VStack flex="1" spacing={4} align="start" m={12} ml="0">
            <Box
              p={3}
              borderRadius="md"
              bg={featureBoxBg}
              boxShadow="sm"
              w="full"
            >
              <CheckCircleIcon boxSize={5} color={headingColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                Customized Mock Tests
              </Text>
              <Text fontSize="sm" color={featureTextColor}>
                Generate tailored customized mock tests for your exam to asses
                and improve yourself.
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={featureBoxBg}
              boxShadow="sm"
              w="full"
            >
              <CheckCircleIcon boxSize={5} color={headingColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                Interview with AI
              </Text>
              <Text fontSize="sm" color={featureTextColor}>
                Practice mock interviews with our AI avatar/agent and crack the
                dream job
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={featureBoxBg}
              boxShadow="sm"
              w="full"
            >
              <CheckCircleIcon boxSize={5} color={headingColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                AI-Powered PDF Evaluation
              </Text>
              <Text fontSize="sm" color={featureTextColor}>
                Get your exam question-answer PDF evaluated with our advanced
                AI.
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={featureBoxBg}
              boxShadow="sm"
              w="full"
            >
              <CheckCircleIcon boxSize={5} color={headingColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                Step-by-Step Solutions
              </Text>
              <Text fontSize="sm" color={featureTextColor}>
                Upload any question image and receive a detailed step-by-step
                solution from AI. Ask any counter questions you may have about
                the solution.
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={featureBoxBg}
              boxShadow="sm"
              w="full"
            >
              <CheckCircleIcon boxSize={5} color={headingColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                Interactive Mock Tests
              </Text>
              <Text fontSize="sm" color={featureTextColor}>
                Speak with our voice assistant to take a voice-based mock viva
                test.
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={specialBoxBg}
              boxShadow="sm"
              w="full"
            >
              <StarIcon boxSize={5} color={specialIconColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                360-Degree Analytics
              </Text>
              <Text fontSize="sm" color={specialTextColor}>
                Get detailed analytics based on your mock tests, pdf
                submissions, and interactions.
              </Text>
            </Box>
            <Box
              p={3}
              borderRadius="md"
              bg={moreFeaturesBoxBg}
              boxShadow="sm"
              w="full"
            >
              <StarIcon boxSize={5} color={moreFeaturesIconColor} mb={1} />
              <Text
                fontSize="md"
                fontWeight="bold"
                mb={1}
                display={"inline"}
                ml="5px"
              >
                More Features
              </Text>
              <Text fontSize="sm" color={moreFeaturesTextColor}>
                Stay tuned for more exciting features coming soon.
              </Text>
            </Box>
          </VStack>
        </Stack>
      </VStack>
    </Box>
  );
};

export default AboutUs;
