import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

const ExamSummary = ({ exam, attempt, score }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const boxBgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("black", "white");
  const headingColor = useColorModeValue("teal.600", "teal.300");
  const sectionTitleColor = useColorModeValue("teal.500", "teal.200");
  const marksColor = useColorModeValue("green.600", "green.300");
  const linkHoverBg = useColorModeValue("gray.100", "gray.700");
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const refreshPage = () => {
    window.location.reload();
  };

  const getSectionStats = (section) => {
    let correctAnswers = 0;
    let incorrectAnswers = 0;
    let sectionMarks = 0;
    let partiallyCorrectAnswers = 0;

    section.questions.forEach((question) => {
      if (question.answerStatus === "correct") {
        correctAnswers++;
        if (question.userMarks > 0) {
          sectionMarks += question.userMarks;
        }
      } else if (question.answerStatus === "inCorrect") {
        incorrectAnswers++;
        if (question.userMarks > 0) {
          sectionMarks -= question.userMarks;
        } else {
          sectionMarks += question.userMarks;
        }
      } else if (question.answerStatus === "partiallyCorrect") {
        partiallyCorrectAnswers++;
        if (question.userMarks > 0) {
          sectionMarks += question.userMarks;
        }
      }
    });

    return {
      correctAnswers,
      incorrectAnswers,
      partiallyCorrectAnswers,
      sectionMarks,
    };
  };

  return (
    <Box
      pt={"1%"}
      textAlign="center"
      position="relative"
      mt="10%"
      maxW={{ base: "95%", lg: "80%" }}
      mx="auto"
    >
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={{ base: 2, lg: 6 }}
        boxShadow="lg"
        bg={bgColor}
        color={textColor}
      >
        <Heading
          as="h1"
          fontSize={{ base: "medium", lg: "xx-large" }}
          mb={4}
          color={headingColor}
        >
          Your total score for {attempt.title} is {score || 0} out of{" "}
          {attempt.totalMarks}
        </Heading>

        <VStack spacing={{ base: 2, lg: 6 }} mt={4} align="stretch">
          <Text fontSize="lg" fontWeight="bold">
            Total Sections: {attempt.sections.length}
          </Text>

          {attempt.sections.map((section, index) => {
            const {
              correctAnswers,
              incorrectAnswers,
              sectionMarks,
              partiallyCorrectAnswers,
            } = getSectionStats(section);
            return (
              <Box
                key={section._id}
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                boxShadow="md"
                bg={boxBgColor}
              >
                <Heading
                  as="h3"
                  fontSize={{ base: "medium", lg: "l" }}
                  mb={2}
                  color={sectionTitleColor}
                >
                  {section.title}
                </Heading>
                <Divider mb={2} />
                <HStack
                  justify="space-between"
                  spacing={{ base: 2, lg: 6 }}
                  fontSize={{ base: "x-small", lg: "large" }}
                >
                  <Text>Total Questions: {section.questions.length}</Text>
                  <Text>Correct Answers: {correctAnswers}</Text>
                  <Text>Incorrect Answers: {incorrectAnswers}</Text>
                  {partiallyCorrectAnswers > 0 && (
                    <Text>
                      Partially Correct Answers: {partiallyCorrectAnswers}
                    </Text>
                  )}
                  <Text fontWeight="bold" color={marksColor}>
                    Marks Obtained: {sectionMarks}
                  </Text>
                </HStack>
              </Box>
            );
          })}
        </VStack>
        <Button
          variant="ghost"
          colorScheme="teal"
          size="lg"
          justifyContent="flex-start"
          _hover={{ bg: linkHoverBg }}
          onClick={refreshPage}
          mt="5%"
          isActive={isActive("/all-mock-tests")}
        >
          <Link to="/dashboard/all-mock-tests">View Details/Solution</Link>
        </Button>

        <Divider my={2} />
        <Button
          onClick={refreshPage}
          colorScheme="teal"
          mt={{ base: 2, lg: 8 }}
          mb={{ base: 2, lg: 8 }}
          boxShadow="md"
        >
          Try another new mock test
        </Button>
      </Box>
    </Box>
  );
};

export default ExamSummary;
