import React from "react";
import { Box, Heading, Text, Button, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <Box
      position="relative"
      bgImage="https://images.pexels.com/photos/4492126/pexels-photo-4492126.jpeg"
      bgRepeat="no-repeat"
      bgSize="cover"
      color="white"
      py="20%"
      px={8}
      textAlign="center"
    >
      {/* Semi-transparent overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)" // Adjust the opacity as needed
        zIndex={1}
      />
      <VStack spacing={6} position="relative" zIndex={2}>
        <Heading as="h1" size="2xl" fontWeight="bold">
          Welcome to TryExam AI
        </Heading>
        <Text fontSize="xl">
          Your personalized AI-powered platform to help you improve and evaluate
          your exam and interview learnings.
        </Text>
        <Text fontSize="lg" maxW="2xl">
          Use AI platform tailored to your needs and asses yourself with
          advanced AI analytics.
        </Text>
        <Button colorScheme="teal" size="lg" mt={4}>
          <Link to="/login">Login to TryExam AI</Link>
        </Button>
      </VStack>
    </Box>
  );
};

export default HeroSection;
