import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  Button,
  Spinner,
  useToast,
  Text,
  Flex,
  VStack,
  Divider,
  Badge,
} from "@chakra-ui/react";
import InterviewDetails from "./InterviewDetails"; // Component for rendering charts
import { hitBeApi } from "../../../api/api";

const CompletedInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [filteredInterviews, setFilteredInterviews] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("title");
  const [filterValue, setFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewingInterview, setViewingInterview] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const response = await hitBeApi(`interview`, "GET");

      if (response.success && response.data?.data) {
        const sortedInterviews = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setInterviews(sortedInterviews);
        setFilteredInterviews(sortedInterviews);

        toast({
          title: "Interviews Loaded",
          description: "Interviews were successfully fetched.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fetch Failed",
          description: response.message || "Failed to fetch interviews.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
    if (!e.target.value) {
      setFilteredInterviews(interviews);
      return;
    }
    const filtered = interviews.filter((interview) =>
      interview[selectedColumn]
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    setFilteredInterviews(filtered);
  };

  const handleViewDetails = async (interviewId) => {
    setViewingInterview("loading");

    try {
      const response = await hitBeApi(`interview/${interviewId}`, "GET");

      if (response.success && response.data) {
        setViewingInterview(response.data);

        toast({
          title: "Interview Details Loaded",
          description: "Interview details were successfully fetched.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fetch Failed",
          description: response.message || "Failed to fetch interview details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        setViewingInterview(null);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      setViewingInterview(null);
    }
  };

  const handleBackToSummaries = () => {
    setViewingInterview(null);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt="4">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p="4">
      {viewingInterview ? (
        <Box>
          {viewingInterview === "loading" ? (
            <Box textAlign="center" mt="4">
              <Spinner size="xl" />
            </Box>
          ) : (
            <Box>
              <Button onClick={handleBackToSummaries} colorScheme="blue" mb={4}>
                Back to All Summaries
              </Button>
              <InterviewDetails data={viewingInterview} />
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box mb="4">
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              mb="2"
            >
              <option value="title">Title</option>
              <option value="domain">Domain</option>
              <option value="role">Role</option>
              <option value="aiAvatarName">AI Avatar</option>
            </Select>
            <input
              type="text"
              placeholder={`Filter by ${selectedColumn}`}
              value={filterValue}
              onChange={handleFilterChange}
              style={{ padding: "8px", width: "100%" }}
            />
          </Box>

          <VStack spacing={4} align="stretch">
            {filteredInterviews.map((interview) => (
              <Box
                key={interview._id}
                p={6}
                bg="white"
                borderRadius="lg"
                boxShadow="md"
                _hover={{ boxShadow: "lg", transform: "scale(1.02)" }}
                transition="all 0.2s ease-in-out"
              >
                <Flex justify="space-between" align="center" mb={4}>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" color="teal.600">
                      {interview.title}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {interview.role} - {interview.domain}
                    </Text>
                  </Box>
                  <Badge
                    colorScheme="green"
                    fontSize="sm"
                    px={3}
                    py={1}
                    borderRadius="full"
                  >
                    {interview.status}
                  </Badge>
                </Flex>

                <Divider mb={4} />

                <Box mb={3}>
                  <Text fontWeight="semibold" color="gray.600">
                    AI Avatar:
                  </Text>
                  <Text color="gray.800">{interview.aiAvatarName}</Text>
                </Box>

                <Box mb={3}>
                  <Text fontWeight="semibold" color="gray.600">
                    Created At:
                  </Text>
                  <Text color="gray.800">
                    {new Date(interview.createdAt)
                      .toLocaleString("en-US", {
                        timeZone: "UTC",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(/(\d{1,2})/, (day) => `${day}th`)}
                  </Text>
                </Box>

                <Box mb={3}>
                  <Text fontWeight="semibold" color="gray.600">
                    Sections:
                  </Text>
                  <Box color="gray.800">
                    {interview.sections.map((section) => (
                      <Text key={section._id} ml={2}>
                        • {section.title} - {section.questionIds.length}{" "}
                        questions
                      </Text>
                    ))}
                  </Box>
                </Box>

                <Flex justifyContent="center" mt={6}>
                  <Button
                    colorScheme="teal"
                    size="lg"
                    width="100%"
                    maxWidth="300px"
                    onClick={() => handleViewDetails(interview._id)}
                  >
                    View Details
                  </Button>
                </Flex>
              </Box>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default CompletedInterviews;
